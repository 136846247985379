import { template as template_c58914553a2748198ee03c48179eec27 } from "@ember/template-compiler";
const WelcomeHeader = template_c58914553a2748198ee03c48179eec27(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
