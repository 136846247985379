import { template as template_e072a1688b2a455282f67c7bf073fdbe } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_e072a1688b2a455282f67c7bf073fdbe(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
