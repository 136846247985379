import { template as template_fc0e07092e95430f88bdcdadb2335199 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc0e07092e95430f88bdcdadb2335199(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
